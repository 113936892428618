<template>
  <div>
    <hero />
    <div id="firebaseui-auth-container" class="mt-5" />
  </div>
</template>

<script>
import { firebase } from '@/main'
require("firebaseui-ja/dist/firebaseui.css");
var firebaseui = require("firebaseui-ja");
var ui = new firebaseui.auth.AuthUI(firebase.auth())

import Hero from '@/components/Hero'

export default {
  components: { Hero },
  data() {
    return {
      redirectTo: this.$route.query.redirect_to || '/',
    }
  },
  mounted() {
    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInSuccessUrl: this.redirectTo,
    });
  },
}
</script>


<style lang="scss" scoped>
  #firebaseui-auth-container ::v-deep {
    .firebaseui-id-submit {
      background: #1e5fbb;
    }

    .firebaseui-id-secondary-link {
      color: #1e5fbb;
    }
  }
</style>